.CalendarGrid {
  width: auto;
  height: calc(100vh - 100px);
  background: red;
}

.timeline {
    position: absolute;
    left: 59px;
    border: none;
    border-top: 1px solid red;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 999;
}